/**
 * @flow
 * @relayHash bb28fa9da02291ef4c628bb9f39ec118
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SubscribeInput = {|
  email: string,
  clientMutationId?: ?string,
|};
export type NotifyMutationVariables = {|
  input: SubscribeInput
|};
export type NotifyMutationResponse = {|
  +subscribe: ?{|
    +clientMutationId: ?string
  |}
|};
export type NotifyMutation = {|
  variables: NotifyMutationVariables,
  response: NotifyMutationResponse,
|};
*/


/*
mutation NotifyMutation(
  $input: SubscribeInput!
) {
  subscribe(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SubscribeInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "subscribe",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubscribePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NotifyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "NotifyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "NotifyMutation",
    "id": null,
    "text": "mutation NotifyMutation(\n  $input: SubscribeInput!\n) {\n  subscribe(input: $input) {\n    clientMutationId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26238f37311fd733a263b45909ef7caa';
module.exports = node;
