import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import MuiToolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, createFragmentContainer } from 'react-relay';

import { useAuth } from '../hooks';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    flexGrow: 1,
  },
}));

function Toolbar(props) {
  const { data } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useAuth();
  const s = useStyles();
  const open = Boolean(anchorEl);

  function openMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function signIn() {
    closeMenu();
    auth.signIn();
  }

  function signOut() {
    closeMenu();
    auth.signOut();
  }

  return (
    <AppBar className={s.root} position="static" color="default" elevation={0}>
      <MuiToolbar>
        <Typography variant="h6" className={s.title}>
          Advisor.im
        </Typography>
        {data.me ? (
          <>
            <IconButton
              aria-owns={open ? 'user-menu' : undefined}
              aria-haspopup="true"
              onClick={openMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right ' }}
              open={open}
              onClose={closeMenu}
            >
              <MenuItem onClick={signOut}>Log Out</MenuItem>
            </Menu>
          </>
        ) : (
          <Button onClick={signIn}>Login</Button>
        )}
      </MuiToolbar>
    </AppBar>
  );
}

export default createFragmentContainer(Toolbar, {
  data: graphql`
    fragment Toolbar_data on Query {
      me {
        id
      }
    }
  `,
});
